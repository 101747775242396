import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../components";

const AnalitykaInternetowa = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Analityka internetowa",
        href: pathname,
        lang: "pl"
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/offer/web-analytics",
      }}
    >
      <section className="container-fluid promotion_section">
        <div className="text-center">
          <h1>Analityka internetowa</h1>
        </div>
        <div className="row promotion_row">
          <div className="col-md-10 m-auto">
            <p className="text-center">
              Podstawą prawidłowo prowadzonych działań marketingowych w
              Internecie jest właściwa analiza statystyk danej witryny. Oparcie
              się o dane dotyczące źródeł ruchu na stronie, zachowania
              użytkowników i dokonywanych przez nich konwersji (realizacji
              założonych celów) umożliwia zarządzanie odnoszącymi sukcesy
              kampaniami.. Bez tych informacji nie ma możliwości prawidłowej
              optymalizacji prowadzonych kampanii marketingowych online. Wiedza
              o tym jakie np. produkty najchętniej sprzedają się z kampanii
              Facebook Ads, a jakie z reklam w wyszukiwarce Google daje nam
              możliwość optymalnego rozłożenia budżetu na poszczególne kanały
              oraz wyboru asortymentu w nim promowanego.
            </p>
            <p className="text-center">
              Posiadana przez nas wiedza analityczna to nie teoria - na
              narzędziach analitycznych pracujemy codziennie. Poznaj narzędzia
              wykorzystywane przez nas każdego dnia:
            </p>
          </div>
        </div>
        <div className="row promotion_row promotion_img">
          <div className="col-md-12 m-auto">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-4 text-center m-auto">
                <img
                  src={require("../../assets/img/offer/web_analyst_logo_ga.png")}
                  className="img-fluid m-auto"
                  alt=""
                />
                <span className="text-promotion mb-1">
                  Podstawowe narzędzie analityczne (konfiguracja także dla
                  biznesów e-commerce)
                </span>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-4 text-center m-auto">
                <img
                  src={require("../../assets/img/offer/web_analyst_logo_gds.png")}
                  className="img-fluid m-auto"
                  alt=""
                />
                <span className="text-promotion mb-1">
                  Testy A/B bez konieczności wprowadzania zmian na stronie
                </span>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-4 text-center m-auto">
                <img
                  src={require("../../assets/img/offer/web_analyst_logo_go.png")}
                  className="img-fluid m-auto"
                  alt=""
                />
                <span className="text-promotion mb-1">
                  Raporty z działań w jednym miejscu
                </span>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-4 text-center m-auto">
                <img
                  src={require("../../assets/img/offer/web_analyst_logo_fb.png")}
                  className="img-fluid m-auto"
                  alt=""
                />
                <span className="text-promotion mb-1">
                  Narzędzie analityczne Facebooka
                </span>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-4 text-center m-auto">
                <img
                  src={require("../../assets/img/offer/web_analyst_logo_hotjar.png")}
                  className="img-fluid m-auto"
                  alt=""
                />
                <span className="text-promotion mb-1">
                  Mapy ciepła, rejestracja wizyt w postaci nagrań wideo
                </span>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-4 text-center m-auto">
                <img
                  src={require("../../assets/img/offer/web_analyst_logo_yandex.png")}
                  className="img-fluid m-auto"
                  alt=""
                />
                <span className="text-promotion mb-1">
                  Mapy ciepła, rejestracja wizyt w postaci nagrań wideo
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row promotion_row2">
          <div className="col-md-10 m-auto">
            <p className="text-center">
              Facebook Ads i LinkedIn Ads daje możliwość skorzystania z
              bezpośredniego dostępu do osób znajdujących się w kręgu Twoich
              zainteresowań – to właśnie klucz do skutecznej rekrutacji i
              gwarancja, że Twoje ogłoszenie trafi prosto do celu! Płacisz
              wyłącznie za kliknięcia w reklamę – a więc za osoby, które
              zainteresowały się ogłoszeniem i chcą dowiedzieć się więcej,
              dzięki czemu unikniesz sporych kosztów promowania oferty na wielu
              różnorodnych portalach ogłoszeniowych.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid section_services">
        <div className="text-center">
          <h2>Wszystkie usługi</h2>
        </div>
        <div className="row">
          <div className="col-md-2 box">
            <span>Banery reklamowe</span>
            <Link to="/oferta/banery-reklamowe/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Social Media</span>
            <Link to="/oferta/social-media/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Linkedin Ads</span>
            <Link to="/oferta/linkedin-ads/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Facebook Ads</span>
            <Link to="/oferta/facebook-ads/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Google Ads</span>
            <Link to="/oferta/ads-google/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Kampanie zagraniczne</span>
            <Link to="/oferta/kampanie-zagraniczne/">zobacz</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default AnalitykaInternetowa;
